// custom runtime exception definitions

// generic unexpected unknown error
export class RuntimeError extends Error
{
  constructor(err, errorInfo, errorCustomData)
  {
    super(err);
    this.info = errorInfo;
    this.customData = errorCustomData;
  }
}

export class TokenizationError extends Error
{
  constructor(err, errorInfo, errorCustomData)
  {
    super(err);
    this.info = errorInfo;
    this.customData = errorCustomData;
  }
}