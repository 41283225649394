import { default as cardValid } from 'card-validator';

export function validateCreditCardValue(card) {
  const result = cardValid.number(card);
  return result.isValid;
}

export function validateMonthFormat(month) {
  const validMonthRE = /^(0?[1-9]|1[012])$/;
  return validMonthRE.test(month);
}

export function validateMonthValue(month) {
  const m = parseInt(month);
  return m < 13 && m > 0;
}

export function validateYearFormat(yr) {
  const validYearRE = /^\d{2}$/;
  return validYearRE.test(yr);
}

export function validateYearValue(yr) {
  var today = new Date();
  var thisYear = today.getFullYear();
  var firstYearPart = thisYear.toString().slice(0,-2);
  var year = parseInt(firstYearPart + yr);
  
  return year >= thisYear;
}

export function validateSecurityCodeValue(securityCode) {
  const validYearRE = /^$|^[0-9]{3,4}$/;
  return validYearRE.test(securityCode);
}


//card_types is an array of accepted card types to check against (ex,  ['mastercard', 'visa'])
export function validateCreditCardType(card_number, card_types)
{
  const cardType = cardValid.creditCardType(card_number)
  
  let isValid = false;
  card_types.forEach((acceptedType) => {
    cardType.forEach((typeInfo) =>
    {
      if (typeInfo.type == acceptedType)
      { //we have found an accepted card type

        isValid = true;
      }
    })
  })

  return isValid;
}




//get a nice display of the credit card number. Technically, not a validation routine, but aligns with the validateCreditCardType function 
export function getCardTypeName(number) {
  var card = cardValid.creditCardType(number.trim());

  if (number.trim().length <= 0 || card === undefined || card.length <= 0) {
    return "";
  } else {
    return card[0].niceType;
  }
}
