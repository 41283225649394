
const AvailableThemes = [
  'label-top-expanded',
  'label-top',
  'label-inline',
  'label-bottom',
  'label-none',
];

// note all the map keys need to be unique.
// this is not ideal
const CSSMAP = {
  input: {
    height: 'height',
    width: 'width',
    font_color: 'color',
    font_size: 'fontSize',
    border_color: 'borderColor',
    background_color: 'backgroundColor',
    border_style: 'borderStyle',
    input_font: 'fontFamily',
    input_font_weight: 'fontWeight',
    input_margin: 'margin',
    input_padding: 'padding',
    input_border_radius: 'borderRadius',
    input_border_width: 'borderWidth'
  },
  label: {
    label_size: 'fontSize',
    label_color: 'color',
    label_font: 'fontFamily',
    label_font_weight: 'fontWeight',
    label_margin: 'margin',
    label_padding: 'padding',
    label_border_style: 'borderStyle',
    label_border_color: 'borderColor',
    label_border_radius: 'borderRadius',
    label_border_width: 'borderWidth',
  },
  fields: {
    padding_bottom: 'paddingBottom',
  },
  fields_label: {
    label_width: 'width',
  }
}

// This function all it does it really map the fields for the api
// to the actual element css style. And only maps ones we allow.
// probably can be refactored some more.
function mapStyles(style, type) {
  if (style) {
    const customStyle = {};
    for (var css in style) {
      if (CSSMAP[type][css]) {
        customStyle[CSSMAP[type][css]] = style[css];
      }
    }
    return Object.assign({}, customStyle);
  }
  return Object.assign({}, style); 
}

// perhaps we can change this to an array or use the collection params
// and use assign to merge the list and with precedence.
// or keep an internal collection...just an idea.
function inputStyle(style) {
  return mapStyles(style, "input");
}

function labelStyle(style) {
  return mapStyles(style, "label");
}

function fieldsStyle(style) {
  return mapStyles(style, "fields");
}  

function fieldsLabelStyle(style){
  return mapStyles(style, "fields_label");
}

export {
  AvailableThemes,
  CSSMAP,
  inputStyle,
  labelStyle,
  fieldsStyle,
  fieldsLabelStyle
}