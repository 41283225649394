import React from 'react';
import withConfig from './withConfig.js';


// component to list and update settings for application.
class Settings extends React.Component
{
  constructor(props)
  {
    super(props);
    this.updateSetting = this.updateSetting.bind(this);
  }
  componentDidMount()
  {
    if (this.props.configRef)
    {
      this.props.configRef(this);
    }
  }
  updateSetting(evt)
  {
    if (evt.target.type == 'checkbox')
    {
      this.setConfig(evt.target.name, evt.target.checked)
    }
    if (evt.target.type == 'number' &&
        /^\d+$/.test(evt.target.value) )
    {
      this.setConfig(evt.target.name, parseInt(evt.target.value));
    }
  }
  
  // global
  setConfig(setting, value)
  {
    if (this.props.updateSettingContext)
    {
      this.props.updateSettingContext(setting, value);
    }
  }
  resetConfig()
  {
    if (this.props.resetSettingContext)
    {
      this.props.resetSettingContext();
    }
  }
  render()
  {
    return (
      <div>
        <h5>Application Configuration</h5>
        
        <div>
          <label>debug enabled:</label>
          <input name='debugEnabled' type='checkbox' 
            onChange={this.updateSetting} 
            checked={this.props.debugEnabled}>
          </input>
        </div>
        
        <div>
          <label>timeout milliseconds:</label>
          <input name='tokenizeTimeout' type='number' 
            onChange={this.updateSetting}
            value={this.props.tokenizeTimeout}>
          </input>
        </div>

        <div>
          <label>iframe height:</label>
          <input name='iFrameHeight' type='number' 
            onChange={this.updateSetting}
            value={this.props.iFrameHeight}>
          </input>
        </div>

        <div>
          <label>iframe width:</label>
          <input name='iFrameWidth' type='number' 
            onChange={this.updateSetting}
            value={this.props.iFrameWidth}>
          </input>
        </div>
        
        <div>
          <label>allow Self Message Post:</label>
          <input name='allowSelfMessagePost' type='checkbox' 
            onChange={this.updateSetting}
            value={this.props.allowSelfMessagePost}
            checked={this.props.allowSelfMessagePost}>
          </input>
        </div>

        <div>
          <label>ENV:</label>
          <input name='hpfENV' type='text'
            readOnly={true}
            value={process.env.NODE_ENV}>
          </input>
        </div>
        
        <div>
          <label>HPF URL:</label>
          <input name='hpfURL' type='text'
            readOnly={true}
            value={process.env.API_URL}>
          </input>
        </div>

      </div>
    )
  }
}

export default withConfig(Settings);