import React from 'react';
import {
  Route,
  withRouter,
} from 'react-router-dom';
import './polyfills';
import { withConfig } from '../config';
import { logger } from '../lib/util';
import { CustomizeErrorResponse as CustomizeValidationError } from '../lib/io';
import { Tokenize, hpf_info } from '../lib/integration';
import { 
  string,
  number,
  object as yupObject,
  test 
} from 'yup'; // for only what you need
import {
  validateCreditCardValue as validateCreditCard,
  validateYearFormat as validateYear,
  validateYearValue,
  validateMonthFormat as validateMonth,
  validateSecurityCodeValue as validateSecurityCode,
  validateCreditCardType,
  getCardTypeName,
} from '../lib/validation';
import {
  SendErrorToServer
} from '../lib/service';

// import './hpf.css';
import styles from './themes.module.scss';
import CreditCard from './credit_card';
import Expiration from './expiration';
import SecurityCode from './security_code';
import MaskedInfo from './masked_info';
import {
  AvailableThemes,
  getClass,
} from '../lib/themes';
import classNames from 'classnames';

// changing the values will break the expectation of the client library.
const HPF_EVENTS = {
    LOADED: "HostedPaymentFormLoadingComplete",
    TOKENIZED: "HostedPaymentCreated",
    FAILED_TOKENIZED: "HostedPaymentCreationFailed",
    HPF_INFO_RETRIEVED: "HostPaymentFormInfoRetrieved",
    HPF_INFO_FAILED: "HostedPaymentFormInfoFailed",
    CC_FOCUS: "creditCard_focus_HPFFieldEvent",
    CC_BLUR: "creditCard_blur_HPFFieldEvent",
    EXP_YY_FOCUS: "expiration_yy_focus_HPFFieldEvent",
    EXP_YY_BLUR: "expiration_yy_blur_HPFFieldEvent",
    EXP_MM_FOCUS: "expiration_mm_focus_HPFFieldEvent",
    EXP_MM_BLUR: "expiration_mm_blur_HPFFieldEvent",
    SEC_FOCUS: "securityCode_focus_HPFFieldEvent",
    SEC_BLUR: "securityCode_blur_HPFFieldEvent",
    FIELDS_VALID: "HostedPaymentFieldsValid",
    FIELDS_INVALID: "HostedPaymentFieldsInvalid",
    STYLE_CHANGED: "StyleChangeSuccess",
    STYLE_CHANGE_FAILED: "StyleChangeFailed",
    THEME_CHANGED: "StyleChangeSuccess",
    THEME_CHANGE_FAILED: "StyleChangeFailed",
}


class Hpf extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      // hosted payment fields sent to server. defined in hostedFields method.
      card_number: '',
      exp_year: '',
      exp_mnth: '',
      security_code: '',
      merchant_id: '', // this should be part of token
      integrator_id: '', // this should be passed into the sale hpf on hermes
      // application ui state
      cc_style: null,
      security_code_style: null,
      expiration_style: {
        // type: ''
        // type: 'TEXTBOX'
      },
      body_style: {
        // background_color: ''
      },
      ccLabelText: 'CC Number',
      secCodeLabelText: 'Security Code',
      expLabelText: 'Expiration',
      templateName: 'label-top',
      // other
      acceptedCardTypes: [],
    }
    
    this.updateCreditCard = this.updateCreditCard.bind(this);
    this.updateSecurityCode = this.updateSecurityCode.bind(this);
    this.updateExpirationMonth = this.updateExpirationMonth.bind(this);
    this.updateExpirationYear = this.updateExpirationYear.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.send = this.send.bind(this);
    this.validateCCType = this.validateCCType.bind(this);

    // YUP validate method is asynchronous and returns a promise. (for sync use syncValidate)
    // validation and internal errors are caught in the catch method.
    this.hpfSchema = yupObject().shape({
      card_number: string()
        .test('validateCreditCard', 'credit card number invalid', validateCreditCard )
        .test('ccType', 'credit card type not allowed', this.validateCCType )
        .required('card number is a required field'),
      exp_year: string()
        .test('validateYear', 'expiration year must be YY', validateYear )
        .test('validateYearValue', 'invalid expiration year', validateYearValue )
        .required('exp year is a required field'),
      exp_mnth: string()
        .test('validateMonth', 'expiration month must be MM', validateMonth )
        .required('expiration month required'),
      security_code: string()
        // .typeError('invalid security code. must be digits')
        .test('requireCSC', 'CSC is required', (v) => {
          return ( (this.state.requireCSC && v.trim().length > 0) || !this.state.requireCSC )
        })
        .test('validateSecurityCode', 'invalid security code. must be 3 to 4 digits', validateSecurityCode ) // requirement depends on setting. 
    });
  }

  componentDidMount()
  {
    logger.info("[HPF CASPER] loaded");
    if (this.props.hpfRef)
    {
      this.props.hpfRef(this);
    }

    if ( this.hostAddress() )
    {
      logger.info("[HPF CASPER] ENV=", process.env.NODE_ENV);
      logger.info("[HPF CASPER] LEV url=", process.env.LEV_URL);

      window.addEventListener('message', this.handleMessage, false);
      this.send(HPF_EVENTS.LOADED, {}, this.hostAddress());
    }
    
    //support for the raw style 
    var styleTag = document.createElement('style');
    //var cssText = ".fields{font-weight: boldest} .fields .fields_label{ color: Red } .fields .fields_control input{ color: blue; font-weight: boldest}" "
    styleTag.setAttribute("id","hpfCss");
    //styleTag.innerHTML = cssText;
    document.head.appendChild(styleTag);
    
  }


  //card types received from server are not readable by validation library routine, so must be transalted by this helper function to be useful 
  translateCardTypes(cardTypes)
  {
    let acceptedCardTypes = []
    for (var index in cardTypes)
    {
      let translatedType = null
      let type = cardTypes[index]
      switch (type)
      {
        case "VS":
        {
          translatedType = "visa"
          break;
        }
        case "MC":
        {
          translatedType = "mastercard"
          break;
        }
        case "DS":
        {
          translatedType = "discover"
          break;
        }
        case "AM":
        {
          translatedType = "american-express"
          break;
        }
        case "DC":
        {
          translatedType = "diners-club"
          break;
        }
        case "JCB":
        {
          translatedType = "jcb"
          break;
        }
      }

      if (translatedType)
      {
        acceptedCardTypes.push(translatedType)
      }
    }

    return acceptedCardTypes
  }
  

  //retrieve hpf specific info from the server. Currently pertains to accepted card types but may contain more info in the future 
  getHpfInfo(authorization, referrerOrigin=null)
  {
    hpf_info(authorization)
    .then(function(response){
      if (response.success)
      {

        let cardTypes = response["cardTypes"];
        let acceptedCardTypes = this.translateCardTypes(cardTypes)

        let requireCSC = response["requireCSC"];
        this.setState({
          acceptedCardTypes: acceptedCardTypes,
          requireCSC: requireCSC
        });

        this.send(HPF_EVENTS.HPF_INFO_RETRIEVED, response.hpfToken, referrerOrigin);
      }
      else
        {
          this.send(HPF_EVENTS.HPF_INFO_FAILED, response.message, referrerOrigin);
        }
    }.bind(this));
  }


  // the external url of the host
  hostAddress()
  {
    if (!document.referrer) {
      logger.warn(`Host url ${document.referrer} undefined for the protect component (at ${document.URL}). Check if correctly hosted on a web page.`);
    }
    return document.referrer;
  }
  
  send(msg, data, origin) 
  {
    if (origin)
    {
      logger.info(`[HPF CASPER] sending ${msg} message to target ${origin}:`, data);
      parent.postMessage({
        eventName: msg,
        data: data || "" // rename to body or payload or something else.
      }, origin);
    }
  }

  handleMessage(msg)
  {
    logger.info("[HPF CASPER] message received from: ", msg.origin, msg.data);

    const url = window.location.protocol + "//" + window.location.host;
    
    if (msg.origin === url)
    {
      if (!this.props.allowSelfMessagePost) {
        logger.info("[HPF CASPER] ignoring message from ", url, msg.origin === url || false);
        return;
      }
    }


    if (msg.data.command == "tokenizeHPF") {
      this.tokenize(msg.data.payload, msg.origin);
    }
    if (msg.data.command == "hpfLoadInfo") {
      this.setState({
        authorization: msg.data.payload               //for future use it may prove worthwhile to have this set in the state 
      });
      this.getHpfInfo(msg.data.payload, msg.origin);
    }
    if (msg.data.command == "styleHPF") {
      this.setStyle(msg.data.payload);
    }
    if (msg.data.command == "setTheme") {
      this.setTheme(msg.data.payload);
    }
    if (msg.data.command == "pingHPF") {
      logger.info("[HPF CASPER] pinged", msg.data.payload);
    }
    if (msg.data.command == "CCLabelTextChange") {
      this.setState({
        ccLabelText: msg.data.payload
      });
    }
    if (msg.data.command == "EXPLabelTextChange") {
      this.setState({
        expLabelText: msg.data.payload
      });
    }
    if (msg.data.command == "SECCODELabelTextChange") {
      this.setState({
        secCodeLabelText: msg.data.payload
      });
    }
    if (msg.data.command == "validateHPF") {
      this.validateFields();
    }
    if (msg.data.command == "resetHPF")
    {
      this.resetHPF();
    }

  }
  
  onFocus(elementName)
  {
    this.send(HPF_EVENTS[elementName+"_FOCUS"], {}, this.hostAddress());
  }

  onBlur(elementName)
  {
    this.send(HPF_EVENTS[elementName+"_BLUR"], {}, this.hostAddress());
  }

  updateCreditCard(value)
  {
    this.setState({
      card_number: value
    });
  }

  updateExpirationMonth(value)
  {
    this.setState({
      exp_mnth: value,
    });
  }
  
  updateExpirationYear(value)
  {
    this.setState({
      exp_year: value,
    });
  }

  updateSecurityCode(value)
  {
    this.setState({
      security_code: value
    });
  }


  validateCCType(card_number)
  {
    //check that the credit card type provided by cardholder is accepted by the merchant
    return validateCreditCardType(card_number, this.state.acceptedCardTypes)
  }


  validateFields()
  {
    const fields = this.hostedFields();
    this.hpfSchema.validate(fields, {abortEarly: false, context: this.state})
    .then(function(data)
    {
      this.send(HPF_EVENTS["FIELDS_VALID"], {}, this.hostAddress());
    }.bind(this))

    .catch(function(err){
      this.send(HPF_EVENTS["FIELDS_INVALID"], CustomizeValidationError(err.errors), this.hostAddress());
    }.bind(this));
  }
  
  hostedFields()
  {
    // https://medium.com/@captaindaylight/get-a-subset-of-an-object-9896148b9c72
    // NOTE: when we remove the integrator ID from the casper request to leviathan to tokenize. remember to remove integrator id here.
    // merchant id is hard code too and should be in token from leviathan.
    const subset = ( ({ card_number, exp_year, exp_mnth, security_code, merchant_id, integrator_id }) => ( { card_number, exp_year, exp_mnth, security_code, merchant_id, integrator_id } ) )(this.state);
    return subset;
  }

  tokenize(clientKey, referrerOrigin)
  {
    const hpfData = this.hostedFields();
    
    this.hpfSchema
      .validate(hpfData, {abortEarly: false, context: this.state})
      .then(function(data) 
      {
        
        Tokenize(clientKey, hpfData)
        .then(function(response){
          if (response.success)
          {
            this.send(HPF_EVENTS.TOKENIZED, response.hpfToken, referrerOrigin);
            this.ccTokenized();          //now that the card has been tokenized, we want to mask the info from display (and anything else post tokenization process)
          }
          else 
          {
            this.send(HPF_EVENTS.FAILED_TOKENIZED, {
              message: "Failed To Process Fields",
              errors: response.message
            }, referrerOrigin);
          }
        }.bind(this));
      }.bind(this))

      .catch(function(err){
        logger.info(err.errors);
        this.send(HPF_EVENTS.FAILED_TOKENIZED, {
          message: "Field Validation Error(s)",
          errors: CustomizeValidationError(err.errors)
        }, this.hostAddress());
      }.bind(this));
  }
  
  
  setRawStyle(raw_style) {
    if(raw_style !== undefined && raw_style != ""){
      var hpfCss = document.getElementById("hpfCss");
      hpfCss.innerHTML = raw_style;
    }
  }
  
  
  setStyle(styleObject)
  {
    // _extends, Object.assign(), spread operator.
    // Object.assign needs polyfill for certain environments.
    // spread operator available in ES6, in the babelrc add {“presets”: [“stage-3”]}
    // lodash library needed for _extends
    try {
      
      const cc_style = {...this.state.cc_style, ...styleObject["cc"] }
      const expiration_style = {...this.state.expiration_style, ...styleObject["exp"] }
      const security_code_style = {...this.state.security_code_style, ...styleObject["code"] }
      const body_style = {...this.state.body_style, ...styleObject["body"] }
    
      //Set the raw style
      this.setRawStyle( styleObject["raw"] );
      
      const newStyles = {
        cc_style: cc_style,
        expiration_style: expiration_style,
        security_code_style: security_code_style,
        body_style: body_style,
      }
      this.setState(newStyles);
      this.send(HPF_EVENTS.STYLE_CHANGED, {
        message: 'style changed successfully'
      }, this.hostAddress());
    } catch(e) {
      this.send(HPF_EVENTS.STYLE_CHANGE_FAILED, {
        message: 'unknown error. failed to change style'
      }, this.hostAddress());
      SendErrorToServer(`Error setting styles: ${e.message}`, e.stack, logger.dump());
    }
  }
  
  setTheme(theme)
  {
    const themeName = theme.trim();
    if (themeName === "") {
      return false;
    }
    if ( AvailableThemes.includes(themeName) ) {
      this.setState({
        templateName: themeName
      });
      this.send(HPF_EVENTS.THEME_CHANGED, {
        message: 'theme changed'
      }, this.hostAddress());
    } else {
      this.setState({
        templateName: ""
      });
      this.send(HPF_EVENTS.THEME_CHANGE_FAILED, {
        message: 'unknown theme'
      }, this.hostAddress());
    }
  }


  resetHPF()
  {
    //ensure cc info has been cleared out and reset the tokenized state 
    this.setState({
      ccType: null,
      ccLastFour: null,
      card_number: null,          //we no longer want to hold a local copy of the cc info, so blank it out 
      exp_mnth: null,
      exp_year: null,
      security_code: null,
      isTokenized: false
    })
    this.clearForm();
  }


  ccTokenized()
  {
    //once the CC has been tokenized, we want to set a state variable indicating as much
    //. and hide the full cc info from the display. 

    //we want to preserve the last 4 and type info for the masked display 
    let ccLastFour = null;
    let ccType = null;
    if (this.state.card_number)
    {
      ccType = getCardTypeName(this.state.card_number)
      ccLastFour = this.state.card_number.substr(this.state.card_number.length - 4)
    }

    this.setState({
      ccType: ccType,
      ccLastFour: ccLastFour,
      card_number: null,          //we no longer want to hold a local copy of the cc info, so blank it out 
      exp_mnth: null,
      exp_year: null,
      security_code: null,
      isTokenized: true
    })

  }

  getRenderAllFields()
  {
    
    return <div id="all_fields" className={this.state.expiration_style.type}> 
      <CreditCard
        template={this.state.templateName}
        labelText={this.state.ccLabelText}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        style={this.state.cc_style}
        defaultValue={this.state.card_number}
        updateCreditCard={this.updateCreditCard}
        errorText="Error"
      />
      <Expiration
        template={this.state.templateName}
        labelText={this.state.expLabelText}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        style={this.state.expiration_style}
        placeholderMonth='mm'
        placeholderYear='yy'
        defaultMonth={this.state.exp_mnth}
        defaultYear={this.state.exp_year}
        updateExpirationMonth={this.updateExpirationMonth}
        updateExpirationYear={this.updateExpirationYear}
      />
      <SecurityCode
        template={this.state.templateName}
        labelText={this.state.secCodeLabelText}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        style={this.state.security_code_style}
        defaultValue={this.state.security_code} 
        updateSecurityCode={this.updateSecurityCode}
      />
    </div> 
  }



  getRenderMaskedinfo()
  {
    return  <div>
                <MaskedInfo
                  template={this.state.templateName}
                  ccLastFour={this.state.ccLastFour}         
                  ccType={this.state.ccType}                 
                /> 
            </div> 
  }


  renderFields()
  {
    let fields = null

    //dont allow viewing of the page unless authorized (code part of MM-76)   (always allow viewing of page in local or dev-qa envs)
    if (this.state.authorization || process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'DEV-QA')                 
    {
      if (this.state.isTokenized)
      {
        fields = this.getRenderMaskedinfo();
      }   
      else
      {
        fields = this.getRenderAllFields()         
      }
    }
    return fields
  }


  setBackground() {
    if (this.state.body_style) {
      document.body.style['backgroundColor'] = this.state.body_style.background_color || 'transparent';
    }
  }

  clearForm() { 
    this.hpfFormRef.reset();
  }

  render() {
    this.setBackground();

    // const theme = this.state.templateName == 'label-inline' ? styles.label_inline : null;
    // const themedClasses = `${theme}`
    const themedClasses = classNames(
      this.state.templateName == 'label-top-expanded' ? styles.label_top_expanded: null,
      this.state.templateName == 'label-inline' ? styles.label_inline : null,
      this.state.templateName == 'label-bottom' ? styles.label_bottom : null,
      this.state.templateName == 'label-top' ? styles.label_top : null,
      this.state.templateName == 'label-none' ? styles.label_none : null,
    );
    return (
      <div id="hpf_fields">
        <Route exact path="/hpf/all">
          <form
            className={themedClasses} 
            ref={(element) => this.hpfFormRef = element}>
          {this.renderFields()}
          </form>
        </Route>
      </div>
    )
  }
}


export default withRouter(withConfig(Hpf));

