import React from 'react';
import {
  uuidv4
} from './uuid';
import {
  SendErrorToServer
} from './service';


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      referenceNumber: null,
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    let reference = uuidv4();
    this.setState({
      referenceNumber: reference
    });
    // TODO check if in new relic that when error is thrown it doesn't already send it to newrelic.
    SendErrorToServer(error, errorInfo, { ref: reference });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h5>Something went wrong.</h5>;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;