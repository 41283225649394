import React from 'react';
import {
  themeTemplate,
  CSSMAP, 
  inputStyle,
  labelStyle,
  fieldsStyle,
  fieldsLabelStyle,
} from '../../lib/themes';


class CreditCard extends React.Component {
  constructor(props) {
    super(props);
    this.updateCreditCard = this.updateCreditCard.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  updateCreditCard(evt) {
    if (this.props.updateCreditCard) {
      this.props.updateCreditCard(evt.target.value);
    }
  }

  // methods below are repeated. refactor this out into a wrapper or composition
  onBlur(evt) {
    if (this.props.onBlur) {
      this.props.onBlur(evt.target.name);
    }
  }
  
  onFocus(evt) {
    if (this.props.onFocus) {
      this.props.onFocus(evt.target.name);
    }
  }

  inputStyle(style = {}) {
    return inputStyle(this.props.style);
  }
  
  labelStyle(style = {}) {
    return labelStyle(this.props.style);
  }

  fieldsStyle(style = {}) {
    return fieldsStyle(this.props.style);
  }  
  
  fieldsLabelStyle(style = {}){
    return fieldsLabelStyle(this.props.style);
  }
  
  render() {
    const label = this.props.labelText;

    return (
      <div className="fields" style={this.fieldsStyle()}>

          <div className="fields_label" style={this.fieldsLabelStyle()}>
            <label style={this.labelStyle()}>
            {label}
            </label>
          </div>

          <div className="fields_control">
            <input
              maxLength={20}
              style={this.inputStyle()}
              onBlur={this.onBlur}
              onFocus={this.onFocus}
              onChange={this.updateCreditCard}
              defaultValue={this.props.defaultValue} 
              placeholder={this.props.placeholder}
              id="credit_card"
              name='CC'
              type='text'
            />
          </div>

      </div>
    )
  }
}

export default CreditCard;