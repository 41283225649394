import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

import { logger } from './lib/util';
import Settings, { Configuration, ConfigurationConsumer } from './config';
import ErrorBoundary from './lib/ErrorBoundary';
// import './App.css';
import Hpf from './hpf';


function TestException() {
  throw "Test Exception Handling";
}

function App() {
  window.ptProtect = {};
  window.ptProtect.logger = logger;
  
  return (
    <Configuration>
    <Router>
      <Switch>
        <Route path="/hpf">
          {
            /* <Hpf hpfRef={ref => (window.hpf = ref)}/> */
            /* Note: should think about refactoring the reference if there is time. */
          }
          <ErrorBoundary>
            <Hpf hpfRef={ref => (window.ptProtect.hpf = ref)} />
          </ErrorBoundary>
        </Route>
        <Route path="/framed_hpf">
          <ErrorBoundary>
            
            <ConfigurationConsumer>
            { 
              (value) => (
                    <iframe
                      height={ value.iFrameHeight }
                      width={ value.iFrameWidth } 
                      src="/hpf/all">
                    </iframe>
              )
            }
            </ConfigurationConsumer>
            

          </ErrorBoundary>
        </Route>
        <Route path="/testerror">
          <ErrorBoundary>
            <TestException />
          </ErrorBoundary>
        </Route>
      </Switch>
    </Router>
    </Configuration>
  );
}

export default App;
