import React from 'react';
import PropTypes from "prop-types";
import hoistStatics from "hoist-non-react-statics";
import invariant from "tiny-invariant";
import { ConfigurationContext } from "./Configuration";

/**
 * A public higher-order component to access the imperative API
 */
function withConfig(Component) {
  const displayName = `withConfig(${Component.displayName || Component.name})`;
  const C = props => {
    const { wrappedComponentRef, ...remainingProps } = props;
  
    return (
      <ConfigurationContext.Consumer>
        {context => {
          invariant(
            context,
            `You should not use <${displayName} /> outside a <Configuration>`
          );
          return (
            <Component
              {...remainingProps}
              {...context}
              ref={wrappedComponentRef}
            />
          );
        }}
      </ConfigurationContext.Consumer>
    );
  };
  
  C.displayName = displayName;
  C.WrappedComponent = Component;
  
  // if (__DEV__) {
  //   C.propTypes = {
  //     wrappedComponentRef: PropTypes.oneOfType([
  //       PropTypes.string,
  //       PropTypes.func,
  //       PropTypes.object
  //     ])
  //   };
  // }
  
  return hoistStatics(C, Component);
}

export default withConfig;
