import {
  RuntimeError
} from '../exception';
import { logger } from '../util';


// errorMessage:    error message.
// errorInfo:       contains stack trace
// errorCustomData: extra custom data to send
function SendErrorToServer(errorMessage, errorInfo, errorCustomData)
{
  try
  {
    logger.warn(errorMessage);
    var err = new RuntimeError(errorMessage, errorInfo, errorCustomData);
    newrelic.noticeError(err);
  }
  catch(e)
  {
    if (e instanceof ReferenceError) {
      // development environment the new relic is not setup
      logger.warn(`Notification Exception: ${e.message} (${process.env.NODE_ENV})`);
    } else {
      throw e; // have no idea what happened
    }
  }
}

export {
  SendErrorToServer
}