import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

//this piece is used for pathing in an s3 env (or any hosting env that doesnt easily support react routing)
//  an error path can be denoted by /#!  This checks for that, routes properly to the asked for page, and pretties the url in the browser
//.  example:  .com/#!/hpf/all   becomes  ./com/hpf/all
//.  Works in combination with info found on https://viastudio.com/hosting-a-reactjs-app-with-routing-on-aws-s3/ for setting up s3 for react routing
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();
const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
if (path) {
    history.replace(path);
}


ReactDOM.render(<App />, document.getElementById('root'));

