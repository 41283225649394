function Queue()
{
  this.maxLength = parseInt(process.env.MAX_LOG_LENGTH) || 10;
  this.elements = [];
}
Queue.prototype.enqueue = function(e) {
  this.elements.push(e);
  if (this.elements.length > this.maxLength) {
    this.elements.shift();
  }
}
Queue.prototype.dequeue = function () {
    return this.elements.shift();
};
Queue.prototype.peek = function () {
    return !this.isEmpty() ? this.elements[0] : undefined;
};
Queue.prototype.length = function() {
    return this.elements.length;
}

export default Queue;