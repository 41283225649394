// MaskedInfo
//
//  desc:
//    displays the payment info in a masked fasion
//    Format will be of "Payment method: MasterCard ending in 5454"
//
//  props:
//    template:       the template to be using for styling purposes
//    ccLastFour:     last 4 digits of credit card being displayed as masked
//    ccType:         the 'nice' name of the credit card that will be displayed 


import React from 'react';


class MaskedInfo extends React.Component
{
  constructor(props)
  {
    super(props)
    this.state = {

    };
  }

  labelStyle() 
  {
    let customStyle = {};
    if (this.props.style) 
    {
      customStyle = 
      {
        color: this.props.style.label_color || null,
        fontSize: this.props.style.label_size || null,
      }
    }
  }

  
  render()
  {
    return(
      <div>
          <label style={this.labelStyle()}>Payment method: {this.props.ccType} ending in {this.props.ccLastFour}</label>
      </div>
    );
  }

}

export default MaskedInfo;