import React from 'react';
import {
  themeTemplate,
  CSSMAP, 
  inputStyle,
  labelStyle,
  fieldsStyle,
  fieldsLabelStyle,
} from '../../lib/themes';
import { PadStart } from '../../lib/util';


class Expiration extends React.Component {
  constructor(props) {
    super(props);
    this.updateExpirationMonth = this.updateExpirationMonth.bind(this);
    this.updateExpirationYear = this.updateExpirationYear.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  updateExpirationMonth(evt) {
    if (this.props.updateExpirationMonth) {
      this.props.updateExpirationMonth(evt.target.value);
    }
  }

  updateExpirationYear(evt) {
    if (this.props.updateExpirationYear) {
      this.props.updateExpirationYear(evt.target.value);
    }
  }

  // methods below are repeated. refactor this out into a wrapper or composition
  onBlur(evt) {
    if (this.props.onBlur) {
      this.props.onBlur(evt.target.name);
    }
  }
  
  onFocus(evt) {
    if (this.props.onFocus) {
      this.props.onFocus(evt.target.name);
    }
  }
  
  inputStyle(style = {}) {
    return inputStyle(this.props.style);
  }
  
  labelStyle(style = {}) {
    return labelStyle(this.props.style);
  }

  fieldsStyle(style = {}) {
    return fieldsStyle(this.props.style);
  }  
  
  fieldsLabelStyle(style = {}){
    return fieldsLabelStyle(this.props.style);
  }
    
  renderFields() {
    if (!this.props.style || !this.props.style.type) {
      return this.renderDropDownFields();
    }
    if (this.props.style.type.toUpperCase() === 'DROPDOWN') {
      return this.renderDropDownFields();
    }
    if (this.props.style.type.toUpperCase() === 'TEXTBOX') {
      return this.renderInputFields();
    }
    return this.renderDropDownFields();
  }

  renderDropDownFields() {
    var currentYear = new Date().getFullYear()
    return (
      <>
        <select 
          style={this.inputStyle()}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onChange={this.updateExpirationMonth}
          defaultValue={this.props.defaultMonth}
          id='expiration_month'
          name='EXP_MM'
        >
          <option key={-1} value={null}>Month</option>
        {
          [1,2,3,4,5,6,7,8,9,10,11,12].map(function(val, idx){
            var month = val;
            var paddedMonth = PadStart(month.toString(), 2, "0");
            return (
              <option key={month} value={month}>{paddedMonth}</option>
            )
          })
        }
        </select>
        
  
        <span>/</span>

        <select
          style={this.inputStyle()}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onChange={this.updateExpirationYear}
          defaultValue={this.props.defaultYear}
          id='expiration_year'
          name='EXP_YY'
        >
          <option key={-1} value={null}>Year</option>
          {
            [0,1,2,3,4,5,6,7,8,9,10].map(function(val, idx){
              var year = currentYear + val;
              var last2 = year.toString().slice(-2);
              
              return (
                <option key={year} value={last2}>{year}</option>
              )
            })
          }
        </select>
      </>
    )
  }

  renderInputFields() {
    return(
      <>
        <input
          style={this.inputStyle()}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onChange={this.updateExpirationMonth}
          defaultValue={this.props.defaultMonth}
          placeholder={this.props.placeholderMonth} 
          id='expiration_month'
          name='EXP_MM'
          type='text'
          maxLength='2'
        />
        <span>/</span>
        <input
          style={this.inputStyle()}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onChange={this.updateExpirationYear}
          defaultValue={this.props.defaultYear}
          placeholder={this.props.placeholderYear} 
          id='expiration_year'
          name='EXP_YY' 
          type='text'
          maxLength='2'
        />
      </>
    )
  }

  render() {
    const label = this.props.labelText;

    // const borderColor = this.props.expiration_style['border-color'];
    return (
      <div className="fields" style={this.fieldsStyle()}>
      
        <div className="fields_label" style={this.fieldsLabelStyle()}>
          <label style={this.labelStyle()}>
          {label}
          </label>
        </div>
        
        <div className="fields_control">
        {
          this.renderFields()
        }
        </div>

      </div>
    )
  }
}

export default Expiration;