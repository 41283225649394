import Queue from './Queue';


const Logger = (function(c){
  const rollingLogs = new Queue();
  
  const consoleOut = function(args, type)
  {
    const state = localStorage.getItem('state');
    const settings = JSON.parse(state);

    rollingLogs.enqueue(JSON.stringify(args));
    
    if (settings && settings['debugEnabled']) {
        args.forEach((v) => {
          c[type](v);
        }); 
    }
  }
  return {
    dump: function()
    {
      consoleOut(rollingLogs);
      return rollingLogs;
    },
    debug: function(...args)
    {
      consoleOut(args, 'log');
    },
    error: function(...args)
    {
      consoleOut(args, 'error');
    },
    warn: function(...args)
    {
      consoleOut(args, 'warn');
    },
    log: function(...args)
    {
      // consoleOut(arguments, 'log');
      consoleOut(args, 'log');
    },
    info: function(...args)
    {
      consoleOut(args, 'log');
    }
  }
})(console);

export default Logger;