import React from 'react';
import axios from 'axios';
// import queryString from 'query-string';
import { logger } from './util';
import {
  SendErrorToServer
} from './service';

const LEV_URL = process.env.API_URL;
const TOKENIZE_API = "/v1/guest/hpf_tokenize.json";
const HPF_INFO_API = "/v1/guest/hpf_info.json";

export function Tokenize(authorization, data) {
  logger.log("[HPF CASPER] payload client key: ", authorization, data);
  logger.log("[HPF CASPER] tokenize: ", LEV_URL);

  let timeout = parseInt(process.env.TOKENIZE_TIMEOUT) || 10000;
  
  const state = JSON.parse(localStorage.getItem('state'));
  if (state && parseInt(state.tokenizeTimeout))
    timeout = parseInt(state.tokenizeTimeout);

  logger.log("timeout = " + timeout);

  var http = axios.create({
    baseURL: LEV_URL,
    timeout: timeout,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': "Bearer ".concat(authorization.clientKey),
    },
  });

  return http
          .post(TOKENIZE_API, data)
          .then(function(response){
            // console.log("[HPF CASPER] result from tokenization", response.data);
            return response.data;
          })
          .catch(function(err) { // unexpected server errors
            logger.error("[HPF CASPER] error tokenization", err);
            SendErrorToServer("[HPF CASPER] error tokenization", err, logger.dump());
          });
}


export function hpf_info(authorization, data={}) {
  logger.log("[HPF CASPER] hpf info: ", LEV_URL, process.env.NODE_ENV, process.env.API_URL);
  
  let timeout = parseInt(process.env.TOKENIZE_TIMEOUT) || 10000;
  
  var http = axios.create({
    baseURL: LEV_URL,
    timeout: timeout,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': "Bearer ".concat(authorization.clientKey),
    },
  });

  return http
          .post(HPF_INFO_API, data)
          .then(function(response){
            // console.log("[HPF CASPER] result from hpf_info", response.data);
            return response.data;
          })
          .catch(function(err) { // unexpected server errors
            console.error("HPF Request %s. %s", HPF_INFO_API, err);
            // logger.error("[HPF CASPER] error hpf_info", err);
            SendErrorToServer("[HPF CASPER] error hpf_info", err, logger.dump());
            return {}
          });
}
