import React from 'react';

export const ConfigurationContext = React.createContext({
  debugEnabled: true, // default if no context was provided which is never
});


// context provider for application settings
class Configuration extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      debugEnabled: false,
      tokenizeTimeout: 10000,
      iFrameHeight: 300,
      iFrameWidth: 1000,
      allowSelfMessagePost: false,
    }
    this.updateSetting = this.updateSetting.bind(this);
    this.resetSetting = this.resetSetting.bind(this);
  }
  
  componentDidMount()
  {
    const state = localStorage.getItem('state');
    const stateJson = JSON.parse(state);
    this.setState(stateJson);
  }
  
  componentDidUpdate(prevProps, prevState)
  {
    localStorage.state = JSON.stringify(this.state);
  }
  
  updateSetting(setting, value)
  {
    const currentSettingValue = this.state[setting];
    if (this.state.hasOwnProperty(setting) && typeof(value) == typeof(currentSettingValue))
    {
      const newConfig = Object.assign({}, this.state);
      let n = {};
      n[setting] = value;
      this.setState(n);
    }
  }
  
  resetSetting()
  {
    localStorage.clear();
  }
  
  contextProps()
  {
    let methods = {
      updateSettingContext: this.updateSetting,
      resetSettingContext: this.resetSetting,
    }
    return {...this.state, ...methods}
  }
  
  render() {
    return (
      <ConfigurationContext.Provider
        children={this.props.children || null}
        value={this.contextProps()}
      />
    );
  }
}
export default Configuration;
