import withConfig from './withConfig.js';
import Configuration, {ConfigurationContext} from './Configuration';
import Settings from './Settings';

const ConfigurationConsumer = ConfigurationContext.Consumer;

export {
  withConfig,
  Configuration,
  ConfigurationConsumer,
}
export default Settings;

