const errorMap = {
  "CREDIT CARD NUMBER INVALID": {
    "responseCode": "35", "description": "Please provide a valid Credit Card Number."
  },
  "CREDIT CARD TYPE NOT ACCEPTED": {
    "responseCode": "35", "description": "Please provide an accepted Credit Card Type."
  },
  "EXPIRATION YEAR MUST BE YY": {
    "responseCode": "44", "description": "Please provide a valid Expiration Year."
  },
  "INVALID EXPIRATION YEAR": {
    "responseCode": "44", "description": "Please provide a valid Expiration Year."
  },
  "EXPIRATION MONTH MUST BE MM": {
    "responseCode": "43", "description": "Please provide a valid Expiration Month."
  },
  "INVALID EXPIRATION MONTH": {
    "responseCode": "43", "description": "Please provide a valid Expiration Month."
  },
  "INVALID SECURITY CODE. MUST BE DIGITS": {
    "responseCode": "148", "description": "Please provide a valid CSC. Must be digits."
  },
  "INVALID SECURITY CODE. MUST BE 3 TO 4 DIGITS": {
    "responseCode": "148", "description": "Please provide a valid CSC. Must be 3 to 4 digits."
  },
  "CSC IS REQUIRED": {
    "responseCode": "148", "description": "Please provide a valid CSC. Must be 3 to 4 digits."
  }
}

export function CustomizeErrorResponse(errorArray) {
  if (!errorArray) {
    return [];
  }
  return errorArray.map( function(value) {
    const key = value.toUpperCase();
    if (errorMap.hasOwnProperty(key) )
      return errorMap[key];
    else
      return { responseCode: "400", description: value };
  });
}